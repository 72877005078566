import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"

import styles from "./columns.module.css"

const Columns = ({ children, gutters, vAlign }) => (
  <div
    className={classNames(styles.columns, {
      [styles.columnsGuttersLarge]: gutters === "large",
      [styles.columnsCentered]: vAlign === "center",
    })}
  >
    {React.Children.map(children, el => (
      <div
        className={classNames(styles.column, {
          [styles.columnGuttersLarge]: gutters === "large",
        })}
      >
        {el}
      </div>
    ))}
  </div>
)

Columns.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Columns
