import PropTypes from "prop-types"
import React from "react"

import styles from "./hero.module.css"

const Hero = ({ bg, children }) => (
  <header className={styles.hero}>
    {bg}
    <div className={styles.inner}>{children}</div>
  </header>
)

Hero.propTypes = {
  children: PropTypes.node,
}

export default Hero
