import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./headline.module.css"

const Headline = ({ children, color, tagName = "h3", textAlign, styling, className }) => {
  if (!styling) styling = tagName
  if (!className) className = ''
  const Tag = tagName
  return (
    <Tag
      className={classNames(
        styles.all,
        styles[styling],
        styles[`align_${textAlign}`],
        {
          [styles["color_white"]]: color === "white",
        },
        className
      )}
    >
      {children}
    </Tag>
  )
}

Headline.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  styling: PropTypes.string,
  tagName: PropTypes.string,
  color: PropTypes.string,
}

export default Headline
