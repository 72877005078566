import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import Headline from "../components/headline"

import styles from "./section.module.css"

const Section = ({ bgColor, children, textAlign, title, className }) => (
  <div
    className={classNames(
      styles.section,
      {
        [styles.sectionBgGrey]: bgColor === "grey",
        [styles.sectionTextCenter]: textAlign === "center",
      },
      className
    )}
  >
    {title && <Headline tagName="h2">{title}</Headline>}
    {children}
  </div>
)

Section.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default Section
