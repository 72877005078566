import React from "react"
import { Link } from "gatsby"

import styles from "./languageSwitch.module.css"

const LanguageSwitch = () => (
  <div className={styles.langSwitch}>
    <Link
      to="/"
      className={styles.langSwitchItem}
      activeClassName={styles.langSwitchItemActive}
      aria-label="Svenska"
    >
      SV
    </Link>
    <Link
      to="/en/"
      className={styles.langSwitchItem}
      activeClassName={styles.langSwitchItemActive}
      aria-label="English"
    >
      EN
    </Link>
  </div>
)

export default LanguageSwitch
