import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./paragraph.module.css"

const Paragraph = ({ children, style, textAlign, className }) => {
  return (
    <p
      className={classNames(
        styles.p,
        styles[`p_align_${textAlign}`],
        className
      )}
      style={style}
    >
      {children}
    </p>
  )
}

Paragraph.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Paragraph
